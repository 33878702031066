/**
 * mo' scroll - scroll triggered animations
 *
 * @description
 * IntersectionObserver polyfill needed for the affected browsers.
 * 
 * (c) 2020 lc
 **/
export function addClassAll(selector, klass) {
  const nodelist = document.querySelectorAll(selector);
  if (!nodelist) {
    return;
  }
  const nodes = Array.prototype.slice.call(nodelist, 0);
  nodes && nodes.forEach((n) => {
    n.classList.add(klass);
  }); 
}

export function registerAos(selector) {
  const CLASS_AOS = 'aos';
  addClassAll(selector, CLASS_AOS);
}

export function moScroll() {

  // options
  const options = {
    rootMargin: "-100px 0px -150px 0px",
    // To enable the 'trackVisibility' option, you must also use a 'delay' option with a value of at least 100.    
    trackVisibility: true, 
    delay: 100 
  };

  /** polyfill needed */
  const observer = new IntersectionObserver(callback, options);

  function observe(selector) {
    const nodelist = document.querySelectorAll(selector);
    const nodes = Array.prototype.slice.call(nodelist, 0);
    nodes && nodes.forEach(function (t) {
      observer.observe(t);
    });  
  }  

  // targets: .aos
  observe('.aos');

  // targets: .scroll-animated
  observe('.scroll-animated');
  
  // callback
  function callback(entries, object) {
    // console.log(object);
    
    entries.forEach(function (entry) {
      // not yet intersecting
      if (!entry.isIntersecting) return;

      // showtime
      const targetElem = entry.target;
      run(targetElem);
      object.unobserve(targetElem);
    });
  };

  function run(elem) {

    const CLASS_ACTION = 'enter';

    // console.log('lazyLoading', elem);

    // target themselves
    if ( elem.classList.contains('aos') ||
      elem.classList.contains('animated') ||    
      elem.classList.contains('scroll-animated')) {
      elem.classList.add(CLASS_ACTION);
      return;
    }

    // target + .whatever_element
    var nextSection = elem.nextElementSibling;
    elem.parentNode.removeChild(elem);
    if (nextSection) {
      nextSection.classList.add(CLASS_ACTION)
    }

  };

}

/** more scroll (fixed section image) */
export function moreScrollFixedSectionImage(selector) {
  const section = document.querySelector(selector);
  if (!section) {
    return;
  }
  const sectionImage = document.createElement('div');
  const imageIndex = Math.round(Math.random() * 1) + 1;
  const backgroundImage = ['/assets/images/lcm', imageIndex, '-min.jpg'].join('');

  console.log(backgroundImage)

  sectionImage.classList.add('fixed-section-image');
  sectionImage.classList.add('animated');
  sectionImage.classList.add('media');
  sectionImage.style.position = 'absolute';
  sectionImage.style.top = 0;
  sectionImage.style.left = 0;
  sectionImage.style.height = '100%';
  sectionImage.style.width = '100%';
  sectionImage.style.backgroundAttachment = 'fixed';
  sectionImage.style.backgroundRepeat = 'no-repeat';
  sectionImage.style.backgroundSize = 'cover'; // must to fit parent
  sectionImage.style.backgroundImage = `url(${backgroundImage})`;
  section.appendChild(sectionImage);

  section.style.backgroundImage = null;
}