/** LC Language Menu (en|ja) */

const currentLanguage = () => {
  const url = "//www.lexcom.co.jp/ja/";
  return "ja";
};
export class LanguageMenu {
  langMenuForm = null;
  supportedLanguages = [{
    icon: 'en',
    title: 'English'
  }, {
    icon: 'ja',
    title: '日本語'
  }];

  init() {
    const language = currentLanguage();
    const browserLang = this.getLanguage();
    const LANGUAGE_CODE_IN_URL = /jp\/([a-z]{2})[\/]{0,1}/; // ja | en or any
    const url = window.location.href,
        urlLang = url.match(LANGUAGE_CODE_IN_URL); 

    if (!urlLang) {
      // do brorser language detection and redirect to site
      window.location.href = "//www.lexcom.co.jp/en";
      return;
    } 
    
    const pageLang = urlLang[1];

    console.log('broswer:', browserLang, 'url', pageLang);


    /** lang-menu-item */
    const parent = document.querySelector('#menu-mymenu');

    const menuItem = document.createElement('li'); 
    menuItem.classList.add('menu-item');
    menuItem.classList.add('language-menu'); 
    const reference = parent && parent.querySelector('.hestia-search-in-menu');
    reference && parent.insertBefore(menuItem, reference);

    const langMenuForm = this.langMenuForm = document.createElement('form');
    menuItem.appendChild(langMenuForm);
    langMenuForm.classList.add('lc-dropdown');
    langMenuForm.classList.remove('form-group')

    /** backdrop */
    const backdrop = document.createElement('div');
    backdrop.classList.add('backdrop');
    langMenuForm.appendChild(backdrop);
    backdrop.addEventListener('click', (e) => this.toggleLanguageMenu());

    /** dropdown wrap (1st item) */
    const dropdownWrap = document.createElement('span');
    dropdownWrap.classList.add('lc-dropdown-wrap');
    dropdownWrap.addEventListener('click', (e) => this.toggleLanguageMenu());

    langMenuForm.appendChild(dropdownWrap);
    let langIcon = document.createElement('span');
    langIcon.classList.add('lang-icon');
    langIcon.textContent = pageLang;
    dropdownWrap.appendChild(langIcon);

    const dropdownBody = document.createElement('div');
    dropdownBody.classList.add('lc-dropdown-body');
    langMenuForm.appendChild(dropdownBody);

    const dropdownDummy = document.createElement('span');
    dropdownDummy.classList.add('lc-dropdown-dummy');
    dropdownDummy.textContent = this.getLanguageTitle(pageLang);
    dropdownDummy.addEventListener('click', (e) => this.toggleLanguageMenu());
    dropdownBody.appendChild(dropdownDummy);

    const dropdownScroll = document.createElement('div');
    dropdownScroll.classList.add('lc-dropdown-scroll');
    dropdownBody.appendChild(dropdownScroll);

    let theRestOfLanguages = [];

    // options
    this.supportedLanguages
    .filter((l) => l.icon != pageLang)
    .forEach((l) => {
      let dropDownItemLink = document.createElement('a');
      dropDownItemLink.classList.add('dropdown-item-link');

      dropDownItemLink.addEventListener('click', (e) => this.setLanguage(e))

      // lang icon (ja|en)
      let langIcon = document.createElement('span');
      langIcon.classList.add('lang-icon');
      langIcon.textContent = l.icon;

      // lang title
      let langTitle = document.createElement('span');
      langTitle.classList.add('lang-title');
      langTitle.textContent = l.title;

      dropDownItemLink.appendChild(langIcon);
      dropDownItemLink.appendChild(langTitle);
      dropdownScroll.appendChild(dropDownItemLink);
    });


  }

  getLanguage() {
    var r = document.cookie.split(';');
    let lang = '';
    r.forEach(function (value) {
      var content = value.split('=');
      if (content[0] == 'lang') {
        lang = content[1];
      }
    });

    // no cookie => detect user language and set cookie as preference
    if (lang == '') {
      lang = this.detectUserLanguage();
      // set en as fallback language
      if (lang !== 'ja') {
        lang = 'en';
      }
    }
    document.cookie = 'lang=' + lang;
    return lang;
  }

  getLanguageTitle(language) {
    for (let l of this.supportedLanguages) {
      if (l.icon == language) {
        return l.title;
      }
    }
    return 'English';
  }

  setLanguage(evt) {
    const target = evt.target;

    const langIcon = target.children[0];
    // const langTitle = this.getLanguageTitle(langIcon.textContent || "");
    const currentLocation = window.location.href;
    // document.cookie = 'lang=' + langIcon.textContent;
    if (langIcon && langIcon.textContent) {
      window.location.href = currentLocation.replace(/jp\/([a-z]{2})[\/]{0,1}/, 'jp/' + langIcon.textContent + '/');
    }
  }


  toggleLanguageMenu() {
    this.langMenuForm.classList.contains('lc-dropdown__opened') ? 
    this.langMenuForm.classList.remove('lc-dropdown__opened') : this.langMenuForm.classList.add('lc-dropdown__opened');
  }

  detectUserLanguage() {
    /**
     * CAVEAT: ts library generator problem
     * @2.9.2 still TS2339: Property 'userLanguage' does not exist on type 'Navigator'...
     */
    var navigator2 = window.navigator;
    var language = navigator2.userLanguage || navigator2.language;
    return language.substr(0, 2);

  }
}