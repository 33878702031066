/**
 * @license
 * Copyright 2020 LexCom Japan K.K. All rights reserved.
 */
import {
  LanguageMenu
} from './LanguageMenu';
import {
  Typewriter
} from './Typewriter';
import {
  addClassAll,  
  registerAos,
  moScroll,
  moreScrollFixedSectionImage
} from './MoScroll';
// import {
//   UnderConstruction
// } from './UnderConstruction';
import {
  ExitStatus
} from 'typescript';

'use strict';

document.addEventListener("DOMContentLoaded", onLoad());

function onLoad() {
  /** body */
  document.querySelector('body').classList.add('lcj');


  /** RWD */
  addMoreRWD(); // adds feature-card

  /** scroll animations */
  // === hestia info
  addClassAll('.hestia-info', 'feature-card');
  registerAos('.hestia-features-title-area');
  registerAos('.feature-card');

  moreScrollFixedSectionImage('.section-image');
  moScroll();


  // let site = new UnderConstruction('https://www.lexcom.de');
  // site.redirect();

  /** visual cue */
  addMoreCue();

  // let typewriter = new Typewriter(".hestia-title");
  // typewriter.init();

  /** search icon */
  decorateSearchIcon();
  
  /** language switch */
  try {
    const langMenu = new LanguageMenu();
    langMenu.init();  
  }
  catch {}
}

// add linear gradient definition for SVG filling 
function decorateSearchIcon() {
  const xmlns = "http://www.w3.org/2000/svg";
  const gradientID = "menu-gradient";
  const searchMenu = document.querySelector('.lcj .hestia-toggle-search'); // anchor
  const svg = searchMenu.firstElementChild;

  if (!searchMenu || !svg) {
    return;
  }

  svg.setAttributeNS(null, "width", "20");
  svg.setAttributeNS(null, "height", "20");

  const defs = document.createElementNS(xmlns, 'defs');
  const grad = document.createElementNS(xmlns, 'linearGradient');
  grad.setAttributeNS(null, "id", gradientID);
  grad.setAttributeNS(null, "x1", "0%");
  grad.setAttributeNS(null, "x2", "0%");
  grad.setAttributeNS(null, "y1", "100%");
  grad.setAttributeNS(null, "y2", "0%");

  // defs
  defs.appendChild(grad);
  // searchMenu
  searchMenu.appendChild(defs);
  searchMenu.firstElementChild.appendChild(defs);  

  // stops (stop1 and stop 2)
  const stop1 = document.createElementNS(xmlns, 'stop');
  const stop2 = document.createElementNS(xmlns, 'stop');
  grad.appendChild(stop1);
  grad.appendChild(stop2);

  stop1.setAttributeNS(null, 'offset', "0%");
  stop1.setAttributeNS(null, 'stop-color', "#13418b");

  stop2.setAttributeNS(null, 'offset', "100%");
  stop2.setAttributeNS(null, 'stop-color', "#82d0f2");

  svg.appendChild(defs);
  svg.firstElementChild.setAttributeNS(null, 'fill', 'url("#' + gradientID + '")');
}

function addMoreCue() {
  /// down chevron
  const carousel = document.querySelector('.lcj .carousel');
  if (carousel) {
    const more = document.createElement('span');
    more.classList.add('more');
    carousel.appendChild(more);  

    // hero image animation
    const ctaButton = carousel.querySelector('.big-title-slider-content a');
    const heroImage = carousel.querySelector('.header-filter');

    if (ctaButton && heroImage) {
      // pointer works only for desktop (no mobile)  
      // ctaButton.addEventListener('pointerdown', () => { heroImage.style.transform = "scale(1.05)"; });
      // ctaButton.addEventListener('pointerup', () => { heroImage.style.transform = "none"; });
      // mouse over out
      ctaButton.addEventListener('mouseover', () => { heroImage.style.transform = "scale(1.05)"; });
      ctaButton.addEventListener('mouseout', () => { heroImage.style.transform = "none"; });
    }    
  }


  // menu list animation
  const navbarToggleBtn = document.querySelector('.navbar-toggle'); 
  const navigation = document.querySelector('#main-navigation');
  const myMenu = document.querySelector('#menu-mymenu');
  const myMenuItems = myMenu && myMenu.querySelectorAll('.menu-item');
  const items = Array.prototype.slice.call(myMenuItems, 0);
  
  // const tl = new TimelineLite();
  // needs Breakpoint Observer too...
  if (false && navbarToggleBtn && myMenuItems && tl) {

    const nodes = Array.prototype.slice.call(myMenuItems, 0);

    // note: 
    // GSAP TimelineLite version 2 =>
    // TimelineLite.min.js:12 Uncaught TypeError: Cannot read property 'repeat' of undefined
    // 2nd param IF duration time is missing (can't specify at 3rd parameters for the tween)
    // GSAP TimelineLite version 3 =>
    // duration IS part of tween parameter object! 
    tl
    .pause()
    .from(nodes[0], .2, { opacity: 0, y: 40})
    .from(nodes[1], .1, { opacity: 0, y: 40})
    .from(nodes[2], .1, { opacity: 0, y: 40})
    .from(nodes[3], .1, { opacity: 0, y: 40})
    // .from(nodes[4], .1, { opacity: 0, y: 40})

    navbarToggleBtn.addEventListener('click', () => {
      // note: @return getAttribute is string and 
      // by the time click occurs, css class has not yet changed.
      if (navigation.getAttribute('aria-expanded') === "false") { // <= from "false"
        tl.play();
      } 
      else {
        tl.reverse();
      }
    })

  }
}

function addMoreRWD() {
  // === big-title-slider-content (funny offset)
  const content = document.querySelector('.big-title-slider-content');
  content && content.classList.remove('col-sm-8');
  content && content.classList.remove('col-sm-offset-2');

  // === footer copyright (centered)
  const copyright = document.querySelector('.copyright');
  copyright && copyright.classList.remove('pull-right');

  // === feature box
  const featuresContent = document.querySelector(".hestia-features-content");
  featuresContent && featuresContent.classList.add('scroll-animated');
  const boxes = document.querySelectorAll('.feature-box');
  if (!boxes) {
    return;
  }
  var nodes = Array.prototype.slice.call(boxes, 0);
  nodes.forEach((n) => {
    n.classList.remove('col-xs-12');
    n.classList.remove('col-md-4');
  });
  
}